import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { apiRoutes } from "../../utils/routes";
import { logoutUser } from '../../redux/slices/authSlice'; 
import store from "../../redux/store";
import { refreshAccessTokenIfNeeded } from '../authApi';

const isDevEnv = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

// TODO Need to add a way of recognizing the NGROK endpoint and add it here isntead of localhost.
const axiosInstance = axios.create({
    baseURL: isDevEnv ? "http://localhost:8000/api" : "https://lift.beoflow.app/api",
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
});

axiosInstance.interceptors.request.use(function(config) {
    const token = localStorage.getItem('accessToken');
    if (token) {
        config.headers.Authorization = `JWT ${token}`;
    }
    return config;
});

// axiosInstance.interceptors.response.use(
//     response => response,
//     error => {
//         // Check if the error response status code is 401
//         if (error.response && error.response.status === 401) {
//             // Dispatch logoutUser action or any other action to handle de-authentication
//             store.dispatch(logoutUser());
//         }
//         return Promise.reject(error);
//     }
// );

axiosInstance.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;
        if ((error.response.status === 401  && !originalRequest._retry)) {
            originalRequest._retry = true;
            try {
                const newAccessToken = await refreshAccessTokenIfNeeded();
                axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                store.dispatch(logoutUser());
            }
        }
        return Promise.reject(error);
    }
);

const refreshAuth = failedRequest => axiosInstance
    .post(apiRoutes.refresh, {"refresh": localStorage.getItem('refreshToken')})
    .then(response => {
        localStorage.setItem('accessToken', response.data.access);
        localStorage.setItem('refreshToken', response.data.refresh);

        failedRequest.response.config.headers['Authorization'] = 'JWT ' + response.data.access;
        axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access;
        return Promise.resolve();
    })
    .catch(error => {
        console.error("Could not refresh token", error);
        // store.dispatch({type: "CLEAR_SESSION"});
        return Promise.reject(error);
    });


createAuthRefreshInterceptor(axiosInstance, refreshAuth);

export default axiosInstance;
